<template>
  <div>
    <!-- Sidebar -->
    <b-sidebar visible="true" id="sidebar" text-variant="dark" no-header="true">
      <img src="https://www.nowworld.com/project_folder/nowworld//images/logo.svg" class="logiSidebar">

      <!-- <b-button :class="visible ? null : 'collapsed'" :aria-expanded="visible ? 'true' : 'false'"
        aria-controls="collapse-4" @click="visible = !visible">
        Toggle Collapse
      </b-button> -->
      <!-- <b-collapse id="collapse-4" v-model="visible" class="mt-2">
        <b-card>I should start open!</b-card>
      </b-collapse> -->



      <ul class="mainMenuList">
        <li class="linkingbase" :class="visibleOTRAMS ? null : 'collapsed'" :aria-expanded="visibleOTRAMS ? 'true' : 'false'" aria-controls="collapse-5" @click="toggleVisibilityOTRAMS">
            <b-icon icon="person-check"></b-icon>
            OTRAMS
            <b-icon icon="chevron-down" class="magicDown"></b-icon>
        </li>
        </ul>

          <b-collapse id="collapse-5" v-model="visibleOTRAMS" class="mt-2">
            <ul class="mainMenuList">
              <li v-for="route in otramsroutes" :key="route.path" :class="{ activeTabasic: isActive(route.path) }">
                <router-link :to="route.path" class="linkingbase">
                  <b-icon :icon="route.icon"></b-icon>
                  {{ route.name }}
                </router-link>
              </li>

              <li>
                <router-link to="/management" class="linkingbase">
                  <b-icon icon="pie-chart-fill"></b-icon>
                  Dashboard
                </router-link>
              </li>

            </ul>
          </b-collapse>

        <!-- <li class="linkingbase" :class="visibleCRM ? null : 'collapsed'" :aria-expanded="visibleCRM ? 'true' : 'false'" aria-controls="collapse-4" @click="toggleVisibilityCRM">
            <b-icon icon="person-check"></b-icon>
            CRM
        </li>


          <b-collapse id="collapse-4" v-model="visibleCRM" class="mt-2">
            <ul class="mainMenuList">
              <li v-for="route in crmroutes" :key="route.path">
                <router-link :to="route.path" class="linkingbase">
                  <b-icon :icon="route.icon"></b-icon>
                  {{ route.name }}
                </router-link>
              </li>

              <li>
                <router-link to="/management" class="linkingbase">
                  <b-icon icon="pie-chart-fill"></b-icon>
                  Dashboard
                </router-link>
              </li>

            </ul>
          </b-collapse>

          </ul> -->


    </b-sidebar>

    <!-- Main content -->
    <!-- <div id="content">
      <b-button @click="toggleSidebar" variant="primary">Toggle Sidebar</b-button>
      
    </div> -->
  </div>
</template>

<script>

import {
  BCollapse
} from 'bootstrap-vue';
import axios from 'axios'
export default {
  components: {
    BCollapse
  },
  data() {
    return {

      visibleOTRAMS: true,
      visibleCRM: false,
      sidebarOpen: true,
      otramsroutes: [
        { path: 'Branch', name: 'Branches', icon: 'diagram-3-fill' },
        { path: 'Role', name: 'Role', icon: 'person-fill' },
        { path: 'Employee', name: 'User', icon: 'person-fill' },
        { path: 'Assignment', name: 'Assign Branches', icon: 'person-check-fill' },
        { path: 'Permissions', name: 'Permissions', icon: 'card-checklist' },
        { path: 'activitylogs', name: 'Activity Logs', icon: 'card-checklist' }
        // Add more routes as needed
      ],
      
      crmroutes: [
        { path: 'BranchCRM', name: 'Branches', icon: 'diagram-3-fill' },
        { path: 'DepartmentCRM', name: 'Departments', icon: 'columns' },
        { path: 'TeamCRM', name: 'Teams', icon: 'people-fill' },
        { path: 'RoleCRM', name: 'Role', icon: 'person-check-fill' },
        { path: 'EmployeeCRM', name: 'Individual/employee', icon: 'person-fill' },
        { path: 'PermissionsCRM', name: 'Permissions', icon: 'card-checklist' }
        // Add more routes as needed
      ]
    };
  },
  methods: {
    isActive(routePath) {
      return this.$route.path === '/' + routePath; // Adjust path comparison as needed
    },
    toggleVisibilityCRM() {
    this.visibleCRM = !this.visibleCRM; // Toggle visibleCRM
    this.visibleOTRAMS = false; // Toggle visible
  },
  toggleVisibilityOTRAMS() {
    this.visibleCRM = false; // Toggle visibleCRM
    this.visibleOTRAMS = !this.visibleOTRAMS; // Toggle visible
  },
    logoutFunc() {
      axios.post('/api/logoutapi',
        { headers: { 'Access-Control-Allow-Origin': '*' } })

        .then(response => {
          console.log(response.data)
          this.$router.push({ name: 'Login' });

          localStorage.clear();

        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
#content {
  padding: 20px;
}

.linkingbase {
  width: 100%;
  display: block
}

.mainMenuList {
  list-style: none;
  text-align: left;
  padding: 10px 0;
}

.mainMenuList li {
  width: 100%;
  padding: 5px 10px;
  /* background: antiquewhite; */
  border-radius: 8px;
  margin: 8px 0px;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  position: relative;
}

.mainMenuList li a {
  cursor: inherit;
  color: inherit;
  text-decoration: inherit;
}

.mainMenuList li:hover {
  background: #4b5ca5;
  color: #fff;
}
.collapse ul{
  list-style: none;
    text-align: left;
    padding: 0 0 0 10px !important;
}
.activeTabasic{
  background: #4b5ca5 !important;
    color: #fff !important;
}
.magicDown{
  position: absolute;
  top:50%;
  right: 6%;
  transform: translateY(-50%)

}
/* .mainMenuList li.active:after {
    content: "";
    position: absolute;
    background-color: transparent;
    top: -77px;
    height: 77px;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 -16px 0 0 #4b5ca5;
    left: 0px;
    z-index: 9;
    transform: rotate(180deg);
    }

    .mainMenuList li.active:before {
    content: "";
    position: absolute;
    background-color: transparent;
    bottom: -76px;
    height: 77px;
    width: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 -16px 0 0 #4b5ca5;
    left: 0px;
    z-index: 9;
} */
</style>
